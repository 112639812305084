import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ScrollingEventService {
    private scrollStatus = new BehaviorSubject(false);
    private isStickyTop = new BehaviorSubject(false);

    sendScrollStatusEvent(isScrolled: boolean) {
        this.scrollStatus.next(isScrolled);
    }

    getScrollStatusEvent(): Observable<boolean> {
        return this.scrollStatus.asObservable();
    }

    sendStickyTopStatus(baseLayout: boolean) {
        this.isStickyTop.next(baseLayout);
    }

    getStickyTopStatus(): Observable<boolean> {
        return this.isStickyTop.asObservable();
    }
}
